@import 'style';

.profile {
	&-row {
		gap: 30px 50px;
		display: flex;
		align-items: center;
		flex-direction: column;

		@include breakpoint('ipad-port') {
			flex-direction: row;
		}

		&-image {
			position: relative;
			width: 100%;
			aspect-ratio: 16/9;

			@include breakpoint('ipad-port') {
				width: 50%;
				aspect-ratio: 1;
			}

			@include breakpoint('ipad-land') {
				width: calc(100% / 3);
			}
		}

		&-content {
			display: block;

			@include breakpoint('ipad-port') {
				width: 50%;
			}

			@include breakpoint('ipad-land') {
				width: calc(100% / 1.5);
			}
		}
	}
}
