@import 'style';

.relatedjobs {
	overflow-x: hidden;
	background-color: white;
	padding: 65 * $px 0;

	&.mod-contentview {
		padding: 0;
	}

	&.mod-jobdetail {
		background-color: $dark-grey;
		color: white;
	}

	&-title + &-block {
		margin-top: 30 * $px;
	}

	&-block {
		background-color: $dark-grey;
		padding: 15 * $px 0;
		color: white;
	}
}

.contentview {
	display: flex;
	flex-direction: column-reverse;

	@include breakpoint('ipad-port') {
		flex-direction: row;
	}

	&-list {
		overflow: hidden;
		background-color: $blue;
		padding: 50 * $px 25 * $px;
		padding-bottom: 25 * $px;
		color: white;

		@include breakpoint('ipad-port') {
			flex: 0 0 auto;
			width: 100%;
			max-width: 375px;
		}

		@include breakpoint('ipad-land') {
			padding: 50 * $px;
		}
	}

	&-jobs {
		display: block;
	}

	&-content {
		flex: 1 1 auto;
		position: relative;
		background-color: white;
		padding: 50 * $px 25 * $px;

		@include breakpoint('ipad-land') {
			padding: 50 * $px;
		}

		@include breakpoint('desktop') {
			padding-left: 100 * $px;
			padding-right: 75 * $px;
		}
	}
}

.jobdetailview {
	&-title {
		padding-bottom: 25 * $px;
		&.mod-jobdetail {
			padding-bottom: 0;
			margin-top: -50px;
			@include breakpoint('tablet') {
				margin-top: 0;
				padding-bottom: 55 * $px;
			}
		}
	}

	&-items {
		@include breakpoint('tablet') {
			margin: 0 -30 * $px;
		}
	}

	&-button {
		line-height: 1.5;
		margin-top: 40 * $px;
		padding: 7.5 * $px 10 * $px;
	}
}

.item {
	position: relative;
	display: block;
	padding: 15 * $px 30 * $px;
	transition: background 0.25s ease-out;

	@include breakpoint('tablet') {
		display: inline-block;
		vertical-align: top;
		width: calc(100% / 2);
		margin: 15 * $px 0;
	}

	@include breakpoint('ipad-land') {
		width: calc(100% / 3);
		padding: 15 * $px 45 * $px;
	}

	@include breakpoint('desktop') {
		&:hover {
			background-color: lighten($dark-grey, 5%);
		}
	}

	&.mod-jobdetail {
		padding: 15 * $px 0;

		@include breakpoint('tablet') {
			padding: 15 * $px 30 * $px;
		}
	}

	&.mod-contentview {
		width: 100%;
		margin: 0;
		padding: 25 * $px 0;

		&:before {
			content: '';
			background-color: lighten($blue, 5%);
			position: absolute;
			top: 0;
			left: -50px;
			height: 100%;
			width: calc(100% + 100px);
			opacity: 0;
			transition: opacity 0.25s ease-out;
		}

		@include breakpoint('desktop') {
			&:hover {
				background: none;

				&:before {
					opacity: 1;
				}
			}
		}

		& + & {
			border-top: 1px solid rgba(#eee, 0.2);
		}
	}

	&-title {
		position: relative;
		color: $blue;

		.mod-contentview & {
			color: inherit;
		}
	}

	&-title + &-description {
		margin-top: 5 * $px;
	}

	&-description {
		line-height: 1.5;
		@include m-lineclamp();
	}

	&-publishdate {
		color: $brown-grey;
		font-size: 14px;
		margin-top: 5 * $px;
	}

	@include breakpoint('tablet') {
		&:nth-child(2n + 1) {
			border-right: 1px solid rgba(#eee, 0.2);
		}
	}

	@include breakpoint('ipad-land') {
		&:nth-child(2n + 1) {
			border-right: none;
		}

		&:nth-child(3n + 1),
		&:nth-child(3n + 2) {
			border-right: 1px solid rgba(#eee, 0.2);
		}
	}

	&.mod-contentview {
		border-right: none;
	}
}
