@import 'style';

.offices {
	overflow-x: hidden;
	background-color: white;

	&-row {
		display: flex;
		flex-wrap: wrap;
	}

	&-left,
	&-right {
		width: 100%;

		@include breakpoint('ipad-land') {
			width: 50%;
			display: inline-flex;
			flex-wrap: wrap;
			vertical-align: top;
		}
	}

	&-left {
		border-top: 1px solid $line;
		border-right: 1px solid $line;

		@include breakpoint('ipad-land') {
			width: calc(100% / 3);
		}
	}

	&-right {
		@include breakpoint('ipad-land') {
			width: calc(100% / 1.5);
		}

		&-item {
			border-top: 1px solid $line;
			border-right: 1px solid $line;

			@include breakpoint('tablet') {
				display: inline-block;
				vertical-align: top;
				width: 50%;
			}
		}
	}
}

.items {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid $line;

	&-item {
		width: 100%;
		border-right: 1px solid $line;
		border-bottom: 1px solid $line;

		@include breakpoint('tablet') {
			display: inline-block;
			vertical-align: top;
			width: 50%;
		}

		@include breakpoint('ipad-land') {
			width: calc(100% / 3);
		}
	}
}

.item {
	width: 100%;
	padding: 31 * $px 0;
	@include gridPaddingLeft(1, 0);
	@include gridPaddingRight(1, 0);

	@include breakpoint('tablet') {
		@include gridPaddingLeft(1, 1);
		@include gridPaddingRight(1, 1);
	}

	@include breakpoint('ipad-land') {
		padding: 80 * $px 50 * $px;
	}

	&-top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-toggler {
			flex: 0 0 auto;
			width: 21 * $px;
			color: $brown-grey;
			margin-left: 15 * $px;
		}
	}

	&-content {
		margin-top: 25 * $px;

		@include breakpoint('ipad-land') {
			font-size: 18 * $px;
			line-height: (30/18);
		}
	}
}
