@import 'style';

.services {
	position: relative;
	background-color: white;

	&.mod-bg {
		padding-top: 80 * $px;
	}

	&-bg_container {
		height: 480 * $px !important;
	}

	&-row {
		gap: 50px 30px;
		display: flex;
		flex-direction: column;

		@include breakpoint('ipad-land') {
			flex-direction: row;
		}

		&.mod-single {
			@include breakpoint('ipad-port') {
				flex-direction: row;
			}
		}

		&-content {
			@include breakpoint('ipad-land') {
				width: calc(100% / 3);
			}
		}

		&-items {
			@include breakpoint('ipad-land') {
				width: calc(100% / 1.5);

				&.mod-bg {
					margin-top: 130 * $px;
				}
			}
		}

		&-content,
		&-items {
			.mod-single & {
				@include breakpoint('ipad-port') {
					width: 50%;
				}
			}
		}
	}
}

.content {
	@include breakpoint('ipad-land') {
		font-size: 18 * $px;
		line-height: (30/18);
	}

	.mod-bg & {
		color: white;
	}
}

.items {
	gap: 50px 30px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include breakpoint('tablet') {
		flex-direction: row;
	}

	&-item {
		width: 100%;

		@include breakpoint('tablet') {
			width: calc(50% - 15px);

			.mod-single & {
				width: 100%;
			}
		}
	}
}
