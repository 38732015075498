@import 'style';

.producthero {
	position: relative;
}

.hero {
	position: relative;
	background-color: white;
	padding: 150 * $px 0 85 * $px 0;

	.hero_breadcrumbs {
		color: inherit;
	}

	&-row {
		position: relative;
	}

	&.mod-image {
		background-color: $dark-grey;
		color: white;
	}

	&.mod-nofilters {
		padding-bottom: 100 * $px;

		@include breakpoint('ipad-land') {
			padding-bottom: 85 * $px;
		}
	}
}

.image,
.content {
	@include breakpoint('ipad-port') {
		width: 50%;
		display: inline-block;
		vertical-align: top;
	}
}

.image + .content {
	margin-top: 50px;

	@include breakpoint('ipad-port') {
		margin-top: 0;
	}
}

.image {
	position: relative;

	&-container {
		max-width: 100%;
		padding-right: 50px;
	}
}

.content {
	&-back {
		padding-bottom: 15px;
		margin-bottom: 35px;
		border-bottom: 1px solid rgba($dark-grey, 0.2);

		.mod-image & {
			border-bottom: 1px solid rgba(white, 0.2);
		}

		&-link {
			&-icon,
			&-text {
				display: inline-block;
				vertical-align: middle;
			}

			&-icon {
				transition: transform 0.25s ease-out;
			}

			&-text {
				font-size: 14 * $px;
				line-height: 1.5;
				font-weight: 500;
				margin-left: 15px;
				border-bottom: 1px solid transparent;
				transition: border 0.25s ease-out;
			}

			&:hover .content-back-link {
				&-icon {
					transform: translateX(-5 * $px);
				}

				&-text {
					border-color: $dark-grey;

					.mod-image & {
						border-color: white;
					}
				}
			}
		}
	}

	&-description {
		.mod-image & {
			color: white;
		}

		color: $dark-grey;
		font-size: 18 * $px;
		line-height: 1.5;
		margin-top: 15px;
	}
}

.filters {
	padding: 50 * $px 0;

	@include breakpoint('desktop') {
		padding: 110 * $px 0 85 * $px 0;
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-top: -15px;

		&-item {
			padding: 15 * $px;
			text-align: center;
			margin-top: 15px;

			@include breakpoint('ipad-land') {
				padding: 25 * $px;
			}

			&-filter {
				color: #d2d0d2;
				font-size: 16 * $px;
				font-weight: 600;

				@include breakpoint('ipad-land') {
					padding-bottom: 5px;
				}
			}

			&-label {
				color: #909090;
				font-size: 24 * $px;

				@include breakpoint('ipad-land') {
					font-size: 30 * $px;
					padding-bottom: 5px;
				}
			}

			& + & {
				border-left: 1px solid #f0f0f0;
			}
		}
	}
}
