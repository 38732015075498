@import 'style';

.overview {
	background-color: $light-grey;
	padding: 75 * $px 0;

	& + & {
		padding-top: 0;
	}

	&-title {
		margin-bottom: 50 * $px;
	}

	&-items {
		margin-top: -30px;

		@include breakpoint('tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-left: -30px;
		}

		&-item {
			margin-top: 30px;

			@include breakpoint('tablet') {
				width: 50%;
				padding-left: 30px;
			}

			.mod-columns3 & {
				@include breakpoint('ipad-land') {
					width: calc(100% / 3);
				}
			}

			.mod-columns4 & {
				@include breakpoint('ipad-land') {
					width: 25%;
				}
			}
		}
	}
}

.videoblock {
	position: relative;
}
