@import 'style';

.videoblock {
	&.mod-overview {
		& > div {
			overflow: hidden;
			background-color: black;
			box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);
			max-width: 100%;
		}
	}
	&:last-child.mod-overview {
		padding-bottom: 0 !important;
	}

	&-content {
		margin: 0 auto;
		max-width: 960 * $px;

		&:global(.mod-small) {
			max-width: 320 * $px;
		}

		&:global(.mod-medium) {
			max-width: 640 * $px;
		}

		&:global(.mod-fullwidth) {
			max-width: 100%;
		}

		&-title {
			margin-bottom: 30 * $px;
		}
	}

	&-iframecontainer {
		position: relative;
		width: 100%;
		aspect-ratio: 16/9;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	&-caption {
		color: rgba($text, 0.5);
		font-style: italic;
		padding-top: 10 * $px;

		@include breakpoint('ipad-port') {
			max-width: calc(100% / (710 / 560));
		}
	}
}
