@import 'style';

.sponsors {
	padding: 20 * $px 0;

	&:last-child {
		padding-bottom: 75 * $px;
	}

	&-title {
		margin-bottom: 45 * $px;
	}
}

.logos {
	&-title {
		margin: 50 * $px 0 20 * $px 0;
	}

	&-items {
		margin-top: -25px;
		margin-left: -25px;

		&-item {
			display: inline-block;
			vertical-align: top;
			padding-left: 25px;
			margin-top: 25px;
		}
	}
}

.logo {
	position: relative;
	display: block;
	height: 125px;
	max-width: 175px;
}
