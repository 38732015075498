@mixin m-scrollbar {
	&::-webkit-scrollbar {
		@content;
		-webkit-overflow-scrolling: auto !important;
	}
}

@mixin m-scrollbar-track {
	&::-webkit-scrollbar-track {
		@content;
	}
}

@mixin m-scrollbar-thumb {
	&::-webkit-scrollbar-thumb {
		@content;
	}
}

@mixin scrollbar(
	$size: 4px,
	$foreground-color: #3f97bd,
	$background-color: #e2e7ed
) {
	@include m-scrollbar {
		width: $size;
		height: $size;
	}

	@include m-scrollbar-thumb {
		background: $foreground-color;
		transition: background 0.25s ease-out;

		&:hover {
			background-color: lighten($foreground-color, 5%);
		}
	}

	@include m-scrollbar-track {
		background: $background-color;
	}
}
