$px: 1em/16;
$rpx: 1rem/16;

@mixin m-lineclamp($lines: 3, $line-height: 28 * $px) {
	overflow: hidden;
	// line-height: $line-height;
	max-height: $lines * $line-height;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
}

@mixin m-lineheight($lines: 3, $line-height: 28 * $px) {
	// line-height: $line-height;
	max-height: $lines * $line-height;
}
