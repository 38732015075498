@import 'style';

.share {
	padding: 45 * $px 0;
	text-align: right;

	&-text,
	&-icons {
		display: inline-block;
		vertical-align: middle;
	}

	&-text + &-icons {
		margin-left: 15 * $px;
	}
}

.link {
	position: relative;
	width: 24 * $px;
	height: 24 * $px;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	background-color: #c1c1c1;
	border-radius: 3 * $px;

	& + & {
		margin-left: 15 * $px;
	}

	&.mod-facebook {
		background-color: $facebook;
	}

	&.mod-linkedin {
		background-color: $linkedin;
	}

	&.mod-twitter {
		background-color: $twitter;
	}

	&-icon {
		color: #f0f0f0;
		font-size: 12 * $px;
	}
}
