@import 'style';

.textpreview {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;

	@include breakpoint('ipad-land') {
		font-size: 18 * $px;
		line-height: (30/18);
	}

	&-wysiwyg {
		h5:before {
			content: '';
			width: 30px;
			height: 4px;
			display: inline-block;
			vertical-align: middle;
			background-color: $blue;
			margin-right: 20px;
		}

		p {
			color: $brown-grey;
		}
	}

	&-link {
		margin-top: 5px;
	}
}
