@import 'style';

.process {
	color: #f9f9f9;
	overflow: hidden;
	position: relative;
	padding: 85 * $px 0;
	background-color: $dark-grey;

	&.mod-links {
		@include breakpoint('tablet') {
			padding-bottom: 170 * $px;
		}
	}

	&-text {
		@include breakpoint('ipad-port') {
			@include gridMaxWidth(10, 9);
		}

		@include breakpoint('ipad-land') {
			font-size: 18 * $px;
			line-height: (30/18);
			@include gridMaxWidth(8, 7);
		}
	}

	&-links {
		width: 100%;

		.mod-links & {
			margin-top: 50 * $px;
		}

		@include breakpoint('tablet') {
			position: absolute;
			left: 0;
			bottom: 30 * $px;
		}

		&-items {
			margin-top: -15px;
			margin-left: -50px;

			@include breakpoint('tablet') {
				display: flex;
				justify-content: space-between;
			}
		}

		&-item {
			display: inline-block;
			vertical-align: top;
			margin-top: 15px;
			padding-left: 50px;
		}

		&-link {
			position: relative;
			padding-bottom: 15px;
			transition: color 0.25s ease-out;

			@include breakpoint('tablet') {
				&:after {
					content: '';
					width: 2px;
					height: 25 * $px;
					position: absolute;
					top: 100%;
					left: 50%;
					border: 1px solid #979797;
					transition: border 0.25s ease-out;
				}
			}

			@include breakpoint('desktop') {
				&:hover {
					color: $blue;

					&:after {
						border-color: $blue;
					}
				}
			}
		}
	}
}
