@import 'style';

$gap: 30 * $px;

.related {
	background: linear-gradient(to bottom, white 360px, $background 360px);

	&:not(:last-child) {
		background: white;
	}

	&-top {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		margin-top: -25 * $px;
		margin-left: -25 * $px;
		margin-bottom: 40 * $px;

		&-title,
		&-nav {
			margin-top: 25 * $px;
			padding-left: 25 * $px;
		}
	}
}

.items {
	display: flex;
	flex-wrap: wrap;
	padding: 0 30 * $px;

	&-block {
		width: 100%;
		flex: 1 0 auto;
		min-height: 535 * $px;
		opacity: 0;
		transition: opacity 0.75s ease-out;

		&.is-active {
			opacity: 1;
		}

		&-left,
		&-right {
			width: 100%;

			@include breakpoint('ipad-port') {
				height: 100%;
				display: inline-flex;
				flex-wrap: wrap;
				vertical-align: top;
				width: calc(50% - (#{$gap} / 2));
			}
		}

		&-right {
			margin-top: $gap;

			@include breakpoint('ipad-port') {
				margin-top: 0;
				margin-left: $gap;
			}

			&-top,
			&-bottom {
				width: 100%;

				@include breakpoint('ipad-port') {
					height: 50%;
				}
			}

			&-top {
				padding-bottom: $gap / 2;

				&-item {
					height: 100%;
				}
			}

			&-bottom {
				display: flex;
				flex-wrap: wrap;
				padding-top: $gap / 2;

				&-item {
					width: 100%;

					@include breakpoint('tablet') {
						display: inline-block;
						vertical-align: top;
						width: calc(50% - (#{$gap} / 2));
					}

					& + & {
						margin-top: $gap;

						@include breakpoint('tablet') {
							margin-top: 0;
							margin-left: $gap;
						}
					}
				}
			}
		}
	}
}
