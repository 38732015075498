@import 'style';

.products {
	&-row {
		gap: 30px 50px;
		display: flex;
		flex-direction: column;

		@include breakpoint('ipad-port') {
			flex-direction: row;
		}
	}

	&-filters {
		@include breakpoint('ipad-port') {
			width: 25%;
		}
	}

	&-content {
		position: relative;

		@include breakpoint('ipad-port') {
			width: 75%;
		}

		&-items {
			margin-top: -50px;

			@include breakpoint('tablet') {
				display: flex;
				flex-wrap: wrap;
				margin-left: -15px;
			}

			&-item {
				margin-top: 50px;

				@include breakpoint('tablet') {
					width: 50%;
					padding-left: 15px;
				}

				@include breakpoint('ipad-land') {
					width: calc(100% / 3);
				}
			}
		}
	}
}

.filters {
	position: relative;
}

.filterssubcategory {
	position: relative;
}

.filterscategory {
	&-title {
		h5 {
			font-weight: 700;
		}

		&.is-open h5:after {
			transform: rotate(180deg);
			transition: transform 0.25s ease-out;
		}
	}

	&-items {
		padding-top: 25px;

		.form_field {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 0;
		}

		&-item {
			&-subcategories {
				margin-left: 20px;

				.form_field {
					display: block;
				}
			}
		}
	}

	& + & {
		margin-top: 35px;
	}
}
