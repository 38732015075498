@import 'style';

.cardpreview {
	position: relative;
}

.image {
	overflow: hidden;
	position: relative;
	display: block;
	width: 100%;
	aspect-ratio: 16/9;
	background: white;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);
	transform: translateY(0);
	transition: transform 0.25s ease-out;

	&:not([href]) {
		cursor: initial;
	}

	&[href]:hover {
		@include breakpoint('desktop') {
			transform: translateY(-20 * $px);
		}
	}

	.mod-bgdark &[href]:hover ~ .content {
		@include breakpoint('desktop') {
			transform: translateY(-20 * $px);
		}
	}

	&.mod-fallback {
		background: $gradient-diagonal;
	}

	&.mod-padding {
		padding: 35 * $px;
	}
}

.content {
	padding: 20px 30px 0 30px;
	transform: translateY(0);
	transition: transform 0.25s ease-out;

	p {
		color: #545454;
	}

	@include breakpoint('ipad-land') {
		font-size: 18 * $px;
		line-height: (30/18);
	}

	&.mod-small {
		@include breakpoint('ipad-land') {
			font-size: 16 * $px;
			line-height: (28/16);
		}
	}

	&.mod-full {
		padding: 20px 0 0 0;
	}

	&.mod-top {
		padding-top: 0;
		padding-bottom: 20px;
		padding-left: 0px;
	}

	.mod-bgdark & {
		color: white;
		background-color: $dark-grey;
		padding: 35px 30px 45px 30px;

		p {
			color: white;
		}
	}
}
