@import 'style';

.tags {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin-top: -15 * $px;
	margin-left: -15 * $px;

	&-item {
		margin-top: 15 * $px;
		padding-left: 15 * $px;

		&-tag {
			display: block;
			color: white;
			font-size: 12 * $px;
			line-height: 1.5;
			font-weight: 500;
			border-bottom: 1px solid white;
			transition: border 0.25s ease-out;

			&:hover {
				@include breakpoint('desktop') {
					border-color: transparent;
				}
			}
		}
	}

	&-dots {
		margin-left: 15px;
	}
}
