@import 'style';

.row {
	& + &,
	& + .formgroup,
	& + .formcomp_field {
		margin-top: 15 * $px;

		@include breakpoint('ipad-port') {
			margin-top: 25 * $px;
		}
	}
}

.warning {
	padding-top: 15 * $px;

	&-message {
		position: relative;
	}
}

.field {
	font-size: 14 * $px;
	line-height: 1.5;
	margin-bottom: 10px;

	&.mod-50 {
		@include breakpoint('ipad-port') {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - #{10 * $px});
		}

		& + & {
			margin-top: 15 * $px;

			@include breakpoint('ipad-port') {
				margin-top: 25 * $px;
			}

			@include breakpoint('ipad-port') {
				margin-top: 0;
				margin-left: 20 * $px;
			}
		}
	}

	&-help {
		font-size: 14 * $px;
		margin-top: 5px;
	}

	&-error {
		font-size: 14 * $px;
		color: #da3d3d;
		margin-top: 5px;

		&:first-letter {
			text-transform: capitalize;
		}
	}

	&.mod-small {
		font-size: 12 * $px;
		line-height: 1.5;
	}

	&.mod-dark & {
		color: #3d3d3d;

		&-label {
			background-color: white;
			color: #3d3d3d;
		}
	}

	&.mod-select &,
	&.mod-textarea &,
	&.mod-text & {
		&-label {
			display: block;
			position: relative;
			overflow-x: auto;
			border-radius: 4px;
			border: 1px solid #dedede;
			transition: border 0.25s ease-out;
			@include scrollbar;

			&.is-focus,
			&:hover {
				border-color: #3f98bd;
			}

			&.is-error {
				color: #da3d3d;
				border-color: #da3d3d;
			}

			&-field {
				font-size: 14 * $px;
				line-height: 1.5;
				width: 100%;
				height: 100%;
				display: block;
				padding: 19.5px 16px 19.5px 16px;

				&.is-filled {
					padding: 26px 16px 13px 16px;
				}

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					font: inherit;
					padding: 26px 16px 13px 16px;
				}

				&:-webkit-autofill + *,
				&:-webkit-autofill:hover + *,
				&:-webkit-autofill:focus + * {
					color: #3f97bc;
					transform: translateY(-105%) scale(0.8);
				}
			}

			&-text {
				position: absolute;
				top: 50%;
				left: 16px;
				transform-origin: top left;
				transform: translateY(-50%);
				transition: 0.25s ease-out;
				pointer-events: none;
				white-space: nowrap;

				&.is-filled {
					color: #3f97bc;
					transform: translateY(-105%) scale(0.8);
				}

				&.is-error {
					color: #da3d3d;
				}
			}
		}
	}

	&.mod-checkbox &,
	&.mod-radio & {
		&-label {
			cursor: pointer;

			&-field {
				position: absolute;
				opacity: 0;
			}

			&-text {
				position: relative;
				padding-left: 14+8px;

				&:before {
					position: absolute;
					left: 0;
					top: 0.2em;
					display: block;
					content: '';
					width: 14px;
					height: 14px;
					border: solid 1px #bfbfbf;
					border-radius: 100%;
					background: white;
					transition: 0.25s ease-out;
				}

				.theme_wysiwyg {
					display: inline-block;
					vertical-align: top;
				}
			}

			&-field:checked + :before {
				box-shadow: inset 0 0 0 2px white;
				background: #3f98bd;
			}
		}
	}

	&.mod-checkbox & {
		&-label {
			&-text {
				&:before {
					border-radius: 2px;
				}
			}
			&-field:checked + :before {
				box-shadow: inset 0 0 0 1px white;
			}
		}
	}

	&.mod-select & {
		&-label {
			display: inline-block;
			min-width: 150px;

			&-field {
				cursor: pointer;
				border-radius: 0;
				appearance: none;
				background: url('~assets/images/dropdown-caret.svg') no-repeat right
					10px top 50% white;
				background-size: 16px 9px;

				&::-ms-expand {
					display: none;
				}
			}
		}
	}

	&.mod-textarea {
		height: calc(100% - 20px);
	}

	&.mod-textarea & {
		&-label {
			height: 100%;
			&-field {
				height: 100%;
				resize: vertical;
			}
			&-text {
				top: 1.21875rem + 0.5rem;
			}
		}
	}
}
