@import 'style';

.testimonials {
	background-color: $dark-grey;
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 85 * $px 0;

	@include breakpoint('ipad-land') {
		padding: 115 * $px 0;
	}

	&-bg {
		opacity: 0;
		z-index: -2;
		transition: opacity 0.5s ease-out;

		&.is-active {
			opacity: 1;
			z-index: -1;
		}
	}

	&-block {
		position: relative;
		color: white;
		display: block;
		background: $gradient-diagonal;
		@include gridMaxWidth(10, 9);
		margin: 0 auto;

		@include breakpoint('ipad-port') {
			@include gridMaxWidth(6, 5);
			margin: 0 0 0 auto;
		}

		&-previous,
		&-next {
			position: absolute;
			z-index: 1;
			top: 50%;
			transform: translate(0, -50%);
			transition: transform 0.25s ease-out;
		}

		&-previous {
			right: calc(100% + 10px);

			@include breakpoint('ipad-port') {
				right: 100%;
			}

			@include breakpoint('ipad-land') {
				right: calc(100% + 20px);
			}

			@include breakpoint('desktop') {
				&:hover {
					transform: translate(-5px, -50%);
				}
			}
		}

		&-next {
			left: calc(100% + 10px);

			@include breakpoint('ipad-port') {
				left: 100%;
			}

			@include breakpoint('ipad-land') {
				left: calc(100% + 20px);
			}

			@include breakpoint('desktop') {
				&:hover {
					transform: translate(5px, -50%);
				}
			}
		}

		&-content {
			position: relative;
			z-index: 1;
			opacity: 0;
			width: 100%;
			flex: 1 0 auto;
			padding: 30 * $px;
			transition: opacity 0.5s ease-out;

			&.is-active {
				opacity: 1;
			}

			@include breakpoint('ipad-port') {
				padding: 55 * $px;
			}
		}

		&-nav {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			margin-top: 25 * $px;
		}
	}
}

.item {
	&-author {
		&-image,
		&-label {
			display: inline-block;
			vertical-align: middle;
		}

		&-image + &-label {
			margin-left: 15 * $px;
		}

		&-image {
			position: relative;
			width: 36 * $px;
			height: 36 * $px;
			overflow: hidden;
			border-radius: 100%;
		}
	}

	&-author + &-quote {
		margin-top: 30 * $px;
	}

	&-quote {
		font-size: 20 * $px;
		line-height: 1.5;
		@include m-lineclamp(5, 28 * $px);

		@include breakpoint('ipad-port') {
			font-size: 24 * $px;
			@include m-lineheight(5, 34 * $px);
		}
	}

	&-link {
		color: white;
		margin-top: 30 * $px;
	}
}
