@import 'style';

.specifications {
	background-color: white;
	padding: 100 * $px 0;

	&-title {
		&-text {
			display: inline-block;
			vertical-align: top;

			&.is-open {
				.theme_h2:after {
					transform: rotate(180deg);
					transition: transform 0.25s ease-out;
				}
			}
		}
	}

	&-items {
		padding-top: 50 * $px;

		@include breakpoint('tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-left: -30px;
		}

		@include breakpoint('ipad-land') {
			margin-left: -60px;
		}

		@include breakpoint('desktop') {
			margin-left: -120px;
		}

		&-col {
			@include breakpoint('tablet') {
				display: inline-block;
				vertical-align: top;
				width: calc(100% / 2);
				padding-left: 30px;
			}

			@include breakpoint('ipad-land') {
				padding-left: 60px;
			}

			@include breakpoint('desktop') {
				padding-left: 120px;
			}

			& + & {
				border-top: 1px solid $line;

				@include breakpoint('tablet') {
					border-top: none;
				}
			}
		}
	}
}

.item {
	padding: 5px 0;

	&-row {
		@include breakpoint('ipad-port') {
			display: flex;
		}
	}

	&-label {
		flex: 0 0 180 * $px;
		font-weight: 700;
		padding-right: 15 * $px;
	}

	&-value {
		font-size: 14 * $px;
	}

	& + & {
		border-top: 1px solid $line;
	}
}
